module.exports = [{
      plugin: require('/Users/satul/Desktop/My Blog/ultra-legends-club/ultra-legends-club/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[],"component":"/Users/satul/Desktop/My Blog/ultra-legends-club/ultra-legends-club/src/layouts/index.js"},
    },{
      plugin: require('/Users/satul/Desktop/My Blog/ultra-legends-club/ultra-legends-club/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/satul/Desktop/My Blog/ultra-legends-club/ultra-legends-club/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/satul/Desktop/My Blog/ultra-legends-club/ultra-legends-club/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-149506305-1 ;"},
    },{
      plugin: require('/Users/satul/Desktop/My Blog/ultra-legends-club/ultra-legends-club/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
